// @flow
//Core
import React from 'react';
import { Link } from '@reach/router';
import classNames from 'classnames';
import { Typography, Link as MUILink, Box } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { BackgroundImage, Image } from 'react-image-and-background-image-fade';
//Local
import styles from './styles.module.scss';
import typography from '../../styles/typography.module.scss';

const Card = ({
  link,
  img,
  tags,
  title,
  content,
  date,
  variant = 'primary',
  theme = 'dark',
  size = 'md',
  className,
  centeredBackground = false,
}: {
  link: string,
  img: string,
  tags: Array<Object>,
  title: string,
  content?: Object,
  variant: string,
  theme: string,
  size: string,
  className?: string,
  date: string,
  centeredBackground: boolean,
}) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography variant="h1">{children}</Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography variant="h2">{children}</Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography variant="h3">{children}</Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography variant="h4">{children}</Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography variant="h5">{children}</Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography variant="h6">{children}</Typography>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography component="p" variant="body1">
          {children}
        </Typography>
      ),
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [INLINES.HYPERLINK]: (node, children) => {
        return <MUILink href={node.data.uri}>{children}</MUILink>;
      },
    },
  };
  const contentJSX = documentToReactComponents(content, options);

  return (
    link &&
    !link.includes('undefined') && (
      <Box
        to={link}
        component={Link}
        className={classNames(styles.cardWrap, className, {
          [styles.cardPrimary]: variant === 'primary',
          [styles.cardSecondary]: variant === 'secondary',
        })}
      >
        <BackgroundImage
          src={variant === 'primary' && img ? img : ''}
          lazyLoad
          className={classNames(styles.card, {
            [styles.cardPrimary]: variant === 'primary',
            [styles.cardSecondary]: variant === 'secondary',
            [styles.cardLight]: theme === 'light',
            [styles.cardDark]: theme === 'dark',
            [styles.cardLg]: size === 'lg',
            [styles.cardMd]: size === 'md',
            [styles.cardSm]: size === 'sm',
            [styles.centeredBackground]: centeredBackground,
          })}
          to={link}
          component={Link}
        >
          {variant === 'secondary' && (
            <Image src={img} alt="" className={styles.cardImg} lazyLoad />
          )}
          <div
            className={classNames(styles.cardDescription, typography.textMd)}
          >
            {tags && (
              <div
                className={classNames(styles.tag, {
                  [typography.textSmLight]: theme === 'dark',
                  [typography.textSmDark]: theme === 'light',
                })}
              >
                {tags.join(', ')}
              </div>
            )}
            {title && (
              <h2
                className={classNames(styles.title, {
                  [typography.titleH6Light]: theme === 'dark',
                  [typography.titleH6Dark]: theme === 'light',
                })}
              >
                {title}
              </h2>
            )}
            {content && <div className={styles.cardChildren}>{contentJSX}</div>}
            {date && (
              <Typography
                variant="subtitle1"
                color={theme === 'light' ? 'primary' : 'secondary'}
                className={styles.cardDate}
              >
                {date}
              </Typography>
            )}
          </div>
        </BackgroundImage>
      </Box>
    )
  );
};

Card.defaultProps = {
  content: null,
  className: '',
};

export default Card;
